import { Outlet } from 'react-router-dom'

import ErrorBoundary from '@/components/error-boundary/ErrorBoundary.jsx'
import GlobalErrorFallback from '@/components/error-boundary/GlobalErrorFallback.jsx'

const PublicRoute = () => {
    return (
        <ErrorBoundary fallback={<GlobalErrorFallback />}>
            <Outlet />
        </ErrorBoundary>
    )
}

export default PublicRoute
