import PropTypes from 'prop-types'

export const PlayIcon = ({ size = 24 }) => {
    return (
        <svg
            width={size}
            height={size}
            viewBox="0 0 14 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M11.0521 7C11.0524 7.12508 11.0204 7.24812 10.959 7.35714C10.8977 7.46616 10.8092 7.55745 10.7022 7.62214L4.06721 11.681C3.95535 11.7495 3.82723 11.7869 3.69608 11.7893C3.56493 11.7918 3.43552 11.7591 3.3212 11.6948C3.20796 11.6315 3.11363 11.5392 3.04792 11.4273C2.9822 11.3155 2.94745 11.1882 2.94727 11.0584V2.94158C2.94745 2.81184 2.9822 2.68451 3.04792 2.57265C3.11363 2.4608 3.20796 2.36847 3.3212 2.30516C3.43552 2.24085 3.56493 2.20822 3.69608 2.21065C3.82723 2.21308 3.95535 2.25048 4.06721 2.31897L10.7022 6.37786C10.8092 6.44255 10.8977 6.53384 10.959 6.64286C11.0204 6.75188 11.0524 6.87491 11.0521 7Z"
                fill={'currentColor'}
            />
        </svg>
    )
}

PlayIcon.propTypes = {
    size: PropTypes.number,
}
