import { useSearchParams } from 'react-router-dom'

import { GoogleMeetIcon } from '@/assets/icons/google-meet.jsx'
import { LinkIcon } from '@/assets/icons/link.jsx'
import { TranslateIcon } from '@/assets/icons/translate.jsx'
import { WaveFormIcon } from '@/assets/icons/waveform.jsx'
import { ZoomIcon } from '@/assets/icons/zoom.jsx'
import {
    ErrorState,
    LoadingState,
} from '@/components/page-states/PageState.jsx'
import { SummaryWrapper } from '@/pages/summary/styles.js'
import { useFileSummarization } from '@/services/queries/useFileSummarization.js'
import { titleGetterBySummaryType } from '@/utilities/helpers.js'

const infoByType = {
    files_url: {
        title: 'Audio link',
        icon: <LinkIcon size={24} />,
    },
    files_uploaded: {
        title: 'Audio file',
        icon: <WaveFormIcon size={24} />,
    },
    files_google_meet: {
        title: 'Google Meet Recording',
        icon: <GoogleMeetIcon size={24} />,
    },
    files_zoom: {
        title: 'Zoom Recording',
        icon: <ZoomIcon size={24} />,
    },
}

const Summary = () => {
    const [params] = useSearchParams()

    const fileId = params.get('file_id')
    const password = params.get('password')
    if (!fileId || !password) {
        throw new Error('file_id or password param missing')
    }
    const { data, isLoading, isError } = useFileSummarization(fileId, password)

    if (isLoading) {
        return <LoadingState />
    }

    if (isError) {
        return <ErrorState />
    }
    return (
        <SummaryWrapper>
            <div className={'heading-container'}>
                <h1>{infoByType[data.type].title}</h1>
                <div className={'language-container'}>
                    <TranslateIcon size={38} />
                    {data.language.name}
                </div>
            </div>
            <div className={'summary-name-container'}>
                <div className={'summary-icon-container'}>
                    {infoByType[data.type].icon}
                </div>
                <div className={'summary-name'}>
                    {titleGetterBySummaryType(data, data.type)}
                </div>
            </div>
            <div className={'summary-container'}>{data.overall_summary}</div>
        </SummaryWrapper>
    )
}

export default Summary
