import { createGlobalStyle, css } from 'styled-components'
import { withTheme } from 'styled-components'

import GilroyBold from '@/assets/fonts/Gilroy-Bold.ttf'
import GilroyRegular from '@/assets/fonts/Gilroy-Regular.ttf'
import GilroySemiBold from '@/assets/fonts/Gilroy-SemiBold.ttf'

const reset = css`
    * {
        -ms-overflow-style: none; /* for Internet Explorer, Edge */
        scrollbar-width: none; /* for Firefox */
    }

    html,
    body,
    #root {
        height: 100%;
        overflow: hidden;
        font-family: 'Gilroy-Regular', sans-serif;
        font-size: 16px;
        color: ${props => props.theme.palette.white};
        background-color: ${props => props.theme.palette.background};
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }

    body {
        min-height: 100vh;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p,
    span,
    small {
        margin-bottom: 0;
    }
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    span {
        font-weight: 0;
    }

    a {
        color: ${props => props.theme.palette.pink};
        cursor: pointer;
        font-weight: 600;
        text-decoration: none;
        &:hover {
            color: ${props => props.theme.palette.purple};
        }
    }

    label,
    input,
    textarea,
    select,
    button {
        font-family: inherit;
    }

    svg[viewBox='0 0 24 24']:not([data-color]) {
        fill: ${props => props.theme.palette.black};
    }

    textarea {
        overflow-wrap: break-word;
    }

    form {
        text-align: left;
    }

    @keyframes spin {
        100% {
            -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
        }
    }
    //Reset Css

    /* Remove all the styles of the "User-Agent-Stylesheet", except for the 'display' property */
    *:where(:not(iframe, canvas, img, svg, video):not(svg *)) {
        all: unset;
        display: revert;
    }

    /* Preferred box-sizing value */
    *,
    *::before,
    *::after {
        box-sizing: border-box;
    }

    /* Remove list styles (bullets/numbers) */
    ol,
    ul {
        list-style: none;
    }

    /* For images to not be able to exceed their container */
    img {
        max-width: 100%;
    }

    /* Removes spacing between cells in tables */
    table {
        border-collapse: collapse;
    }

    /* Revert the 'white-space' property for textarea elements on Safari */
    textarea {
        white-space: revert;
    }

    ::-webkit-scrollbar {
        width: 5px;
    }

    ::-webkit-scrollbar-track {
        background: transparent;
    }

    ::-webkit-scrollbar-thumb {
        background: rgb(0, 0, 0, 0.4);
        border-radius: 5px;
    }

    ::-webkit-scrollbar-thumb:hover {
        background: rgb(0, 0, 0, 0.5);
    }
`

const GlobalStyles = createGlobalStyle`
    ${reset}

    @font-face {
        font-family: 'Gilroy-Bold'; /*Can be any text*/
        src: local('Gilroy-Bold'),
        url(${GilroyBold}) format('truetype');
    }

    @font-face {
        font-family: 'Gilroy-Regular'; /*Can be any text*/
        src: local('Gilroy-Regular'),
        url(${GilroyRegular}) format('truetype');
    }

    @font-face {
        font-family: 'Gilroy-Semibold'; /*Can be any text*/
        src: local('Gilroy-Semibold'),
        url(${GilroySemiBold}) format('truetype');
    }


`

export default withTheme(GlobalStyles)
