import styled, { css } from 'styled-components'

import AixplainButton from '@/components/aixplain-button/AixplainButton.jsx'

export const UploaderButton = styled(AixplainButton)`
    ${({ theme: { spacing, palette, mediaQueries }, value }) => css`
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        gap: ${spacing}px;
        border: 2px solid ${palette.purple};
        background-color: ${palette.purple}26;

        ${mediaQueries.mUp} {
            ${value &&
            css`
                display: none;
            `}
        }
    `}
`

export const UploadedFileWrapper = styled.div`
    ${({ theme: { spacing, typography, palette } }) => css`
        width: 90%;
        max-width: 800px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: ${spacing * 2}px;
        padding-bottom: ${spacing}px;
        border-bottom: 1px solid ${palette.darkerGray};

        .file-name-container {
            width: 100%;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            gap: ${spacing * 2}px;
            overflow: hidden;
        }

        .remove-icon-container {
            display: grid;
            place-items: center;
            cursor: pointer;
            color: ${palette.danger};
        }

        .file-name {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;

            ${typography.fontSemiBold}
            ${typography.textLg}
        }
    `}
`

const bgColorByDragState = {
    error: css`
        ${({ theme: { palette } }) => css`
            background-color: ${palette.danger}28;
        `}
    `,
    success: css`
        ${({ theme: { palette } }) => css`
            background-color: ${palette.success}28;
        `}
    `,
    idle: css`
        background-color: unset;
    `,
}

export const UploaderWrapper = styled.div`
    ${({
        theme: { spacing, palette, typography, mediaQueries },
        dragState,
        value,
    }) => css`
        width: 100%;
        overflow: hidden;
        display: flex;
        padding: ${spacing * 3}px 0;
        ${bgColorByDragState[dragState]}
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: ${spacing * 4}px;

        .icon-tile-container {
            display: flex;
            width: 100%;
            justify-content: center;
            align-items: center;
            place-items: center;
            ${!value &&
            css`
                margin-bottom: -${spacing * 2}px;
            `}
        }

        .uploader-action-container,
        .info-section {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            text-align: center;
        }

        .info-section {
            ${typography.fontRegular}
            ${typography.textSm}
        }

        .uploader-action-container {
            ${value &&
            css`
                flex-direction: row;
                gap: 5px !important;
            `}
        }

        .uploader-action-container {
            gap: ${spacing}px;
        }

        .error-message {
            color: ${palette.danger};
            ${typography.fontRegular}
            ${typography.textXs}
        }

        .uploader-tile,
        .browse-text {
            ${typography.fontSemiBold}
            ${typography.textSm}
        }

        .browse-text {
            color: ${palette.purple};
            cursor: pointer;
            ${!value &&
            css`
                display: none;
            `}
        }

        .or {
            ${typography.fontSemiBold}
            ${typography.textXs}

            ${value &&
            css`
                ${typography.fontSemiBold}
                ${typography.textSm}
            `}
        }

        ${mediaQueries.m} {
            .uploader-tile,
            .or,
            .browse-text {
                display: none;
            }
        }
    `}
`
