import PropTypes from 'prop-types'
import { forwardRef } from 'react'

import {
    StyledTextField,
    TextFieldWrapper,
    StyledErrorMessage,
} from '@/components/textfield/styles.js'

const TextField = forwardRef(function Component(
    {
        value,
        readOnly,
        onChange,
        onBlur,
        error,
        placeholder,
        textFieldClassName,
        errorMessageClassName,
    },
    ref
) {
    return (
        <TextFieldWrapper>
            <StyledTextField
                ref={ref}
                readOnly={readOnly}
                className={textFieldClassName}
                value={value}
                onChange={e => onChange(e.target.value)}
                onBlur={onBlur}
                error={error}
                placeholder={placeholder}
            />
            {error && (
                <StyledErrorMessage className={errorMessageClassName}>
                    {error}
                </StyledErrorMessage>
            )}
        </TextFieldWrapper>
    )
})

TextField.propTypes = {
    value: PropTypes.string,
    readOnly: PropTypes.bool,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    error: PropTypes.string,
    placeholder: PropTypes.string,
    textFieldClassName: PropTypes.string,
    errorMessageClassName: PropTypes.string,
}
export default TextField
