import styled, { css } from 'styled-components'

export const SummaryWrapper = styled.div`
    ${({ theme: { spacing, mediaQueries, typography } }) => css`
        padding-top: ${spacing * 10}px;

        ${mediaQueries.s} {
            padding-top: ${spacing * 4}px;
        }

        .heading-container {
            margin-bottom: ${spacing * 4}px;
            display: flex;
            justify-content: space-between;
            align-items: center;

            h1 {
                ${typography.textXl}
                ${typography.fontBold}
            }
        }

        .language-container {
            display: flex;
            align-items: center;
            gap: ${spacing * 1.25}px;
            ${typography.textLg}
            ${typography.fontRegular}
        }

        .summary-name-container {
            margin-bottom: ${spacing * 8}px;
            display: flex;
            align-items: center;
            gap: ${spacing * 1.25}px;
            overflow: hidden;
            .summary-name {
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                flex-grow: 1;
                ${typography.textMd}
            }

            .summary-icon-container {
                display: grid;
                place-items: center;
            }
        }

        .summary-container {
            ${typography.textMd}
            ${typography.fontRegular}
        }
    `}
`
