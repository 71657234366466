import { createBrowserRouter, Navigate } from 'react-router-dom'

import PublicLayout from '@/layouts/public-layout/PublicLayout.jsx'
import * as pages from '@/pages'
import PublicRoute from '@/route-guards/public-route/PublicRoute.jsx'
import * as routeNames from '@/utilities/constants'

const router = createBrowserRouter(
    [
        {
            element: <PublicRoute />, // anonymous user layout route
            children: [
                {
                    element: <PublicLayout />,
                    children: [
                        {
                            path: routeNames.ROUTE_UPLOAD_AUDIO,
                            element: <pages.UploadAudio />,
                        },
                        {
                            path: routeNames.ROUTE_AUDIO_LINK,
                            element: <pages.AudioLink />,
                        },
                        {
                            path: routeNames.GOOGLE_MEET,
                            element: <pages.GoogleMeetRecording />,
                        },
                        {
                            path: routeNames.ZOOM,
                            element: <pages.ZoomRecording />,
                        },
                        {
                            path: routeNames.THANK_YOU,
                            element: <pages.ThankYou />,
                        },
                        {
                            path: routeNames.SUMMARIZATIONS,
                            element: <pages.Summarizations />,
                        },
                        {
                            path: routeNames.SUMMARY,
                            element: <pages.Summary />,
                        },
                        {
                            path: '/',
                            element: <pages.Home />,
                        },
                    ],
                },
            ],
        },
        {
            path: '/',
            element: <Navigate to={routeNames.ROUTE_UPLOAD_AUDIO} />,
        },
        {
            path: '*',
            element: <pages.NotFound />,
        },
    ],
    {
        basename: '/',
    }
)

export default router
