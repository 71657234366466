import { useQuery } from '@tanstack/react-query'

import { getLanguages } from '@/services/summarization.js'

export const useLanguages = queryOptions => {
    return useQuery({
        queryKey: ['languages'],
        queryFn: () => getLanguages(),
        ...queryOptions,
    })
}
