import { css } from 'styled-components'

import GlobalStyles from './global.js'
import mediaQueries from './mediaQueries'
import { palette } from './palette'
import { shadows } from './shadows'
import { typography } from './typography'

export const spacing = 8
const getAppTheme = () => {
    const zIndex = {
        modal: 1300,
        modalOverlay: 1299,
        snackbar: 1400,
        tooltip: 1500,
    }

    const truncateText = css`
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    `

    return {
        spacing,
        zIndex,
        mediaQueries,
        palette,
        typography,
        shadows,
        truncateText,
    }
}

export { GlobalStyles, getAppTheme }
