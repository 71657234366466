import { useNavigate } from 'react-router-dom'

import AixplainButton from '@/components/aixplain-button/AixplainButton.jsx'
import Spinner from '@/components/spinner/Spinner.jsx'

import { CenteredWrapper, StyledErrorText } from './styles.js'

export const LoadingState = () => {
    return (
        <CenteredWrapper>
            <Spinner size={50} />
        </CenteredWrapper>
    )
}

export const ErrorState = () => {
    const navigate = useNavigate()
    return (
        <CenteredWrapper>
            <StyledErrorText>Something went wrong!</StyledErrorText>
            <AixplainButton
                variant={'primary'}
                role={'link'}
                onClick={() => navigate('/')}
            >
                Go back to Homepage
            </AixplainButton>
        </CenteredWrapper>
    )
}
