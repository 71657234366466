import * as Tooltip from '@radix-ui/react-tooltip'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { RouterProvider } from 'react-router-dom'
import { ThemeProvider } from 'styled-components'

import { Toaster } from '@/components/toaster/Toaster.jsx'
import { getAppTheme, GlobalStyles } from '@/theme'

import router from './router.jsx'

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            staleTime: 1000 * 60 * 5,
            refetchOnWindowFocus: false,
        },
    },
})

function App() {
    return (
        <ThemeProvider theme={getAppTheme()}>
            <QueryClientProvider client={queryClient}>
                <Tooltip.Provider>
                    <GlobalStyles />
                    <Toaster />
                    <RouterProvider router={router} />
                </Tooltip.Provider>
            </QueryClientProvider>
        </ThemeProvider>
    )
}

export default App
