import styled, { css } from 'styled-components'

export const HomeWrapper = styled.div`
    ${({ theme: { spacing, typography, palette, mediaQueries } }) => css`
        padding: ${spacing * 5}px 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        gap: ${spacing * 6}px;

        h1 {
            ${typography.fontSemiBold}
            ${typography.textXXl}
        }

        h2 {
            ${typography.fontSemiBold}
            ${typography.textLg}
        }

        h3 {
            ${typography.fontRegular}
            ${typography.textSm}
        }

        .options-container {
            width: 100%;
            max-width: 800px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            gap: ${spacing * 3}px;
        }

        .option {
            cursor: pointer;
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            gap: ${spacing * 2}px;
            padding: ${spacing * 2}px;
            border-radius: 12px;
            border: 4px solid transparent;
            background: linear-gradient(${palette.background} 0 0) padding-box,
                ${palette.rainbowGradient} border-box;
        }

        ${mediaQueries.s} {
            padding-top: ${spacing * 4}px;
            gap: ${spacing * 6}px;
        }
    `}
`
