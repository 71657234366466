export const palette = {
    pink: '#DB5BA1',
    white: '#fff',
    success: '#65CA63',
    danger: '#EA4335',
    warning: '#F99848',
    background: '#262D33',
    black: '#000',
    purple: '#A164F7',
    lighterGray: '#B6BFC9',
    lightGray: '#6C8093',
    mediumGray: '#313942',
    darkGray: '#383E47',
    darkerGray: '#1B2025',
    rainbowGradient:
        'linear-gradient(to right,#65ca63,#59b8c9,#9869ef,#db5ba1,#ea9b58,#d0bc25)',
}
