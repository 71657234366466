import PropTypes from 'prop-types'

export const DownloadIcon = ({ size = 24 }) => {
    return (
        <svg
            width={size}
            height={size}
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M3.34229 13.6187V13.6181V11.8089H3.88176V13.6181V14.1181H4.38176H13.6186H14.1186V13.6181V11.8089H14.6581V13.6181C14.6581 13.9077 14.5607 14.1434 14.3519 14.3522C14.1431 14.561 13.9079 14.6579 13.6192 14.6576H13.6186H4.38176C4.09219 14.6576 3.85648 14.5602 3.64768 14.3514C3.43889 14.1426 3.34194 13.9074 3.34229 13.6187ZM5.85246 8.22381L6.23539 7.8272L7.87689 9.4687L8.73044 10.3223V9.11515V3.3418H9.26992V9.11515V10.3223L10.1235 9.4687L11.765 7.8272L12.1479 8.22381L9.00018 11.3715L5.85246 8.22381Z"
                fill="currentColor"
                stroke="currentColor"
            />
        </svg>
    )
}

DownloadIcon.propTypes = {
    size: PropTypes.number,
}
