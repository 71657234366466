import styled, { css } from 'styled-components'

export const SummarizationTemplateForm = styled.form`
    ${({ theme: { spacing, mediaQueries } }) => css`
        gap: ${spacing * 12}px;
        width: 100%;
        min-height: 100%;
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-top: ${spacing * 5}px;
        padding-bottom: ${spacing * 5}px;

        .main-section-container {
            display: flex;
            flex-direction: column;
            gap: ${spacing * 2}px;
        }

        ${mediaQueries.s} {
            gap: ${spacing * 8}px;
            padding-bottom: ${spacing * 12}px;
        }

        .language-select,
        .language-select-spinner {
            margin-left: auto;
            margin-bottom: ${spacing * 3}px;
        }
    `}
`

export const MainContentWrapper = styled.div`
    ${({ theme: { palette } }) => css`
        min-height: 256px;
        overflow: hidden;
        display: grid;
        place-items: center;
        border-radius: 32px;
        border: 2px solid transparent;
        background: linear-gradient(${palette.background} 0 0) padding-box,
            ${palette.rainbowGradient} border-box;
    `}
`

export const SubmitSectionWrapper = styled.div`
    ${({ theme: { spacing } }) => css`
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: ${spacing * 3}px;
        align-items: start;
        justify-content: center;
        width: 100%;

        .email-test-field-container,
        .submit-button {
            width: 330px;
        }
    `}
`
