import { css } from 'styled-components'

export const typography = {
    fontBold: css`
        font-family: 'Gilroy-Bold', sans-serif;
    `,
    fontRegular: css`
        font-family: 'Gilroy-Regular', sans-serif;
    `,
    fontSemiBold: css`
        font-family: 'Gilroy-SemiBold', sans-serif;
    `,

    textXXl: css`
        font-weight: 0;
        font-size: 2.5rem;
        line-height: 2.5rem;
        letter-spacing: -0.5px;
    `,

    textXl: css`
        font-weight: 0;
        font-size: 2rem;
        line-height: 2rem;
        letter-spacing: -0.5px;
    `,

    textLg: css`
        font-weight: 0;
        font-size: 1.125rem;
        line-height: 1.125rem;
        letter-spacing: -0.5px;
    `,

    textMd: css`
        font-weight: 0;
        font-size: 1rem;
        line-height: 1.125rem;
        letter-spacing: -0.5px;
    `,

    textSm: css`
        font-weight: 0;
        font-size: 0.875rem;
        line-height: 1.125rem;
        letter-spacing: -0.5px;
    `,

    textXs: css`
        font-weight: 0;
        font-size: 0.75rem;
        line-height: 1.125rem;
        letter-spacing: -0.5px;
    `,
}
