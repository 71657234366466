import styled, { css } from 'styled-components'

export const GlobalErrorFallbackWrapper = styled.div`
    ${({ theme: { spacing, typography } }) => css`
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        gap: ${spacing * 4}px;
        h1 {
            ${typography.textXl}
            ${typography.fontBold}
        }
    `}
`
