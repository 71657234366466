import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { useAudioPlayer } from 'react-use-audio-player'

import { PauseIcon } from '@/assets/icons/pause.jsx'
import { PlayIcon } from '@/assets/icons/play.jsx'
import {
    AudioPlayerWrapper,
    TrackWrapper,
} from '@/components/audio-player/styles.js'
import Spinner from '@/components/spinner/Spinner.jsx'
import { secondsToMMSS } from '@/utilities/helpers.js'

const AudioPlayer = ({ src }) => {
    const { load, play, isReady, playing, pause, duration, getPosition } =
        useAudioPlayer()

    const [trackPosition, setTrackPosition] = useState(0)
    const [intervalId, setIntervalId] = useState(null)

    const percentage = duration ? (trackPosition / duration) * 100 : 0

    useEffect(() => {
        const interval = setInterval(() => {
            setTrackPosition(getPosition())
        }, 1000)
        return () => {
            clearInterval(interval)
        }
    }, [getPosition])

    useEffect(() => {
        return () => {
            clearInterval(intervalId)
        }
    }, [intervalId])

    useEffect(() => {
        load(src, { autoplay: false, format: 'wav' })
    }, [src, load])

    const startTrack = () => {
        if (!isReady) {
            return
        }
        const interval = setInterval(() => {
            setTrackPosition(getPosition())
        }, 1000)
        setIntervalId(interval)
        play()
    }

    const pauseAudio = () => {
        pause()
        clearInterval(intervalId)
    }

    let controlIcon
    if (!isReady) {
        controlIcon = <Spinner size={14} />
    } else if (!playing) {
        controlIcon = (
            <div
                onClick={startTrack}
                role={'button'}
                className={'icon-container'}
            >
                <PlayIcon size={14} />
            </div>
        )
    } else {
        controlIcon = (
            <div
                onClick={pauseAudio}
                role={'button'}
                className={'icon-container'}
            >
                <PauseIcon size={14} />
            </div>
        )
    }

    return (
        <AudioPlayerWrapper>
            {controlIcon}
            <span className={'time-tracker'}>{` ${secondsToMMSS(
                trackPosition
            )} / ${secondsToMMSS(duration)}`}</span>
            <TrackWrapper percentage={percentage}>
                <div className={'track-bg'}>
                    <div className={'track-current'} />
                </div>
            </TrackWrapper>
        </AudioPlayerWrapper>
    )
}

AudioPlayer.propTypes = {
    src: PropTypes.string,
}
export default AudioPlayer
