import styled, { css } from 'styled-components'

export const TextFieldWrapper = styled.div`
    ${({ theme: { spacing } }) => css`
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: start;
        justify-content: center;
        gap: ${spacing}px;
    `}
`
export const StyledTextField = styled.input`
    ${({ theme: { spacing, palette }, error }) => css`
        width: 100%;
        padding: ${spacing * 1.5}px ${spacing * 3}px;
        padding: ${spacing * 1.5}px ${spacing * 3}px;
        background-color: ${palette.darkerGray};
        border-radius: 12px;
        height: 48px;

        border: 2px solid ${error ? palette.danger : palette.black};
    `}
`

export const StyledErrorMessage = styled.small`
    ${({ theme: { palette, typography } }) => css`
        color: ${palette.danger};
        ${typography.fontRegular}
        ${typography.textXs}
    `}
`
