import PropTypes from 'prop-types'

export const ChevronDownIcon = ({ size = 24 }) => {
    return (
        <svg
            width={size}
            height={size}
            viewBox="0 0 14 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M11.5967 3.43005L7.00004 8.02672L2.40337 3.43005L1.16671 4.66672L7.00004 10.5001L12.8334 4.66672L11.5967 3.43005Z"
                fill={'currentColor'}
            />
        </svg>
    )
}

ChevronDownIcon.propTypes = {
    size: PropTypes.number,
}
