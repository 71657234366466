import { axiosInstance } from '@/services/axiosIstance.js'

export const summarizeAudioFile = payload => {
    const formData = new FormData()
    Object.entries(payload).forEach(([k, v]) => formData.append(k, v))
    return axiosInstance.post('/files-uploaded', formData).then(res => res.data)
}

export const summarizeAudioLink = payload =>
    axiosInstance.post('/files-urls', payload).then(res => res.data)

export const summarizeGoogleMeetLink = payload =>
    axiosInstance.post('/files-google-meet', payload).then(res => res.data)

export const summarizeZoomRecording = payload =>
    axiosInstance.post('/files-zoom', payload).then(res => res.data)

export const getLanguages = () =>
    axiosInstance.get('/languages').then(res => res.data)

export const getUserSummarization = (userId, password) =>
    axiosInstance
        .get(`/users/${userId}/files?password=${password}`)
        .then(res => res.data)

export const getFileSummarization = (fileId, password) =>
    axiosInstance
        .get(`/files/${fileId}?password=${password}`)
        .then(res => res.data)
