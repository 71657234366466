import { useMutation, useQueryClient } from '@tanstack/react-query'
import toast from 'react-hot-toast'
import { useNavigate } from 'react-router-dom'
import * as yup from 'yup'

import ZoomRecordingForm from '@/pages/zoom-recording/ZoomRecordingForm.jsx'
import { summarizeZoomRecording } from '@/services/summarization.js'
import * as routeNames from '@/utilities/constants/index.js'

import SummarizationTemplate from '../../templates/summarization/SummarizationTemplate.jsx'

const formSchema = yup.object().shape({
    email: yup
        .string()
        .email('Enter a valid email')
        .required('This field is required'),

    zoom_link: yup
        .string()
        .url('Enter a valid url')
        .matches(
            /^https:\/\/[a-z0-9-]+\.zoom\.us\/rec\/play\/[a-zA-Z0-9-_]+\.[a-zA-Z0-9-_]+$/,
            'Enter a valid zoom link'
        )
        .required('This field is required'),
    passcode: yup.string().required('This field is required'),
    language_id: yup.number().required('This field is requires'),
})

const ZoomRecording = () => {
    const navigate = useNavigate()
    const queryClient = useQueryClient()

    const { mutate, isLoading } = useMutation({
        mutationFn: payload => summarizeZoomRecording(payload),
        onSuccess: () => {
            queryClient.invalidateQueries(['user-summarizations']).then(() => {
                navigate({
                    pathname: routeNames.THANK_YOU,
                })
            })
        },
        onError: error => {
            toast.error('Ops! Something went wrong, try again later.')
            console.error(error)
        },
    })

    const onSubmit = values => {
        mutate({ ...values, language_id: parseInt(values.language_id) })
    }

    return (
        <>
            <SummarizationTemplate
                isLoading={isLoading}
                title={'Zoom Recording'}
                steps={[
                    'Record your Zoom meeting',
                    'Share the recording to “Anyone with the link”',
                    'Select the recording’s language',
                    `Press "Summarize it"`,
                ]}
                defaultValues={{ zoom_link: '', passcode: '' }}
                formSchema={formSchema}
                onSubmit={onSubmit}
            >
                <ZoomRecordingForm />
            </SummarizationTemplate>
        </>
    )
}

export default ZoomRecording
