import styled, { css } from 'styled-components'

const colorByVariant = {
    primary: css`
        ${({ theme: { palette } }) => css`
            background-color: ${palette.pink};
            border: 1px solid ${palette.pink};
        `}
    `,

    success: css`
        ${({ theme: { palette } }) => css`
            background-color: ${palette.success};
            border: 1px solid ${palette.success};
        `}
    `,
}
export const StyledButton = styled.button`
    ${({ theme: { spacing, typography, palette }, variant }) => css`
        border-radius: 12px;
        white-space: nowrap;
        text-align: center;
        cursor: pointer;
        height: 48px;
        ${typography.fontBold}
        ${typography.textMd}
        padding: ${spacing * 1.5}px ${spacing * 3}px;
        ${colorByVariant[variant]}

        &:disabled {
            background-color: ${palette.darkGray};
            border: 1px solid ${palette.lighterGray};
            cursor: not-allowed;
        }
    `}
`
