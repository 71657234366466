import styled, { css } from 'styled-components'

export const TitleAndStepsWrapper = styled.div`
    ${({ theme: { spacing, typography } }) => css`
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        gap: ${spacing * 2}px;

        h1 {
            ${typography.fontBold}
            ${typography.textXXl}
        }

        p {
            ${typography.textSm}
            ${typography.fontRegular}
        }

        .steps-container {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            gap: ${spacing / 4}px;
        }
    `}
`
