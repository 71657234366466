import PropTypes from 'prop-types'
import { useDropzone } from 'react-dropzone'

import { AddFileIcon } from '@/assets/icons/addFile.jsx'
import { CheckIcon } from '@/assets/icons/check.jsx'
import { CrossIcon } from '@/assets/icons/crossIcon.jsx'
import { UploadIcon } from '@/assets/icons/upload.jsx'

import {
    UploadedFileWrapper,
    UploaderButton,
    UploaderWrapper,
} from './styles.js'

const Uploader = ({ value, onChange, name, error }) => {
    const { getRootProps, getInputProps, isDragReject, isDragAccept, open } =
        useDropzone({
            maxFiles: 1,
            maxSize: 1073741824,
            onDrop: acceptedFiles => {
                onChange(acceptedFiles[0])
            },
            accept: {
                'audio/wav': [],
                'audio/x-wav': [],
                'audio/mpeg': [],
                'audio/mp4': [],
                'audio/x-m4a': [],
                'audio/m4a': [],
            },
            noClick: true,
        })

    const dragState = isDragReject ? 'error' : isDragAccept ? 'success' : 'idle'

    return (
        <UploaderWrapper
            {...getRootProps()}
            dragState={dragState}
            value={value}
        >
            <input {...getInputProps()} name={name} />
            <div className={'icon-tile-container'}>
                {!value ? (
                    <UploadIcon size={38} />
                ) : (
                    <UploadedFileWrapper>
                        <CheckIcon size={38} />
                        <div className={'file-name-container'}>
                            <span className={'file-name'}>{value.name}</span>
                            <div
                                className={'remove-icon-container'}
                                role={'button'}
                                onClick={e => {
                                    e.stopPropagation()
                                    onChange(undefined)
                                }}
                            >
                                <CrossIcon size={16} />
                            </div>
                        </div>
                    </UploadedFileWrapper>
                )}
            </div>
            <div className={'uploader-action-container'}>
                <p className={'uploader-tile'}>Drag and Drop a file</p>
                <p className={'or'}>or</p>
                <UploaderButton type={'button'} onClick={open} value={value}>
                    <span>Browse a file</span>
                    <AddFileIcon size={20} />
                </UploaderButton>
                <span className={'browse-text'} role={'button'} onClick={open}>
                    {' '}
                    browse
                </span>
            </div>
            <div className={'info-section'}>
                <p>Check supported formats: wav, mp3, mp4, m4a</p>
                <p>File size limit: 1GB.</p>
            </div>
            {error && <small className={'error-message'}>{error}</small>}
        </UploaderWrapper>
    )
}

Uploader.propTypes = {
    name: PropTypes.string,
    onChange: PropTypes.func,
    value: PropTypes.instanceOf(File),
    error: PropTypes.string,
}

export default Uploader
