import styled, { css } from 'styled-components'

export const StyledRoot = styled.div`
    position: fixed;
    top: 0;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
`

export const StyledGithubRibbonAnchor = styled.a`
    ${({ theme: { mediaQueries } }) => css`
        position: absolute;
        left: 0;
        display: none;
        ${mediaQueries.m} {
            display: none;
        }
    `}
`

export const ContentWrapper = styled.div`
    ${({ theme: { spacing } }) => css`
        position: relative;
        flex-grow: 1;
        padding-left: ${spacing * 2}px;
        padding-right: ${spacing * 2}px;
        display: flex;
        justify-content: center;
        justify-items: center;
        overflow: auto;

        .content {
            width: 1220px;
            max-width: 100%;
        }
    `}
`
