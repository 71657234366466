import styled, { css } from 'styled-components'

export const AudioPlayerWrapper = styled.div`
    ${({ theme: { palette, spacing, typography } }) => css`
        height: 38px;
        overflow: hidden;
        display: flex;
        align-items: center;
        padding: ${spacing}px ${spacing * 2}px;
        border-radius: 16px;
        gap: ${spacing * 2}px;
        border: 2px solid transparent;
        background: linear-gradient(${palette.background} 0 0) padding-box,
            ${palette.rainbowGradient} border-box;

        .icon-container {
            display: grid;
            place-items: center;
            cursor: pointer;
        }

        .time-tracker {
            white-space: nowrap;
            ${typography.fontRegular}
            ${typography.textXs}
        }
    `}
`

export const TrackWrapper = styled.div`
    ${({ theme: { palette }, percentage }) => css`
        flex-grow: 1;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;

        .track-bg {
            width: 983px;
            max-width: 90%;
            height: 3px;
            background-color: ${palette.darkGray};
            overflow: hidden;
        }

        .track-current {
            height: 100%;
            width: 100%;
            background-color: ${palette.purple};
            transform: translateX(-${100 - percentage}%);
        }
    `}
`
