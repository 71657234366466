import styled from 'styled-components'

export const AudioLinkFormWrapper = styled.div`
    width: 100%;
    .link-text-field-container {
        width: 90%;
        max-width: 590px;
        margin: auto;
    }
    .link-text-field {
        height: 40px;
        width: 100%;
        max-width: 590px;
    }
`
