import { useMemo } from 'react'
import { useFormContext } from 'react-hook-form'

import AudioPlayer from '@/components/audio-player/AudioPlayer.jsx'

const UploadedAudioPlayer = () => {
    const { watch } = useFormContext()

    const file = watch('file')

    const audioSrc = useMemo(() => {
        if (!file) {
            return ''
        }
        return URL.createObjectURL(file)
    }, [file])

    if (!audioSrc) {
        return null
    }

    return <AudioPlayer key={audioSrc} src={audioSrc} />
}

export default UploadedAudioPlayer
