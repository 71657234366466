import PropTypes from 'prop-types'

export const LinkIcon = ({ size = 24 }) => {
    return (
        <svg
            width={size}
            height={size}
            viewBox="0 0 38 38"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M13.8704 31.9968C12.3144 31.9975 10.793 31.5367 9.49894 30.6725C8.20485 29.8084 7.1962 28.5798 6.60061 27.1422C6.00502 25.7046 5.84926 24.1226 6.15304 22.5965C6.45683 21.0703 7.20651 19.6686 8.30721 18.5686L12.7185 14.1541L15.0202 16.4535L10.6089 20.8692C10.1805 21.2977 9.84081 21.8063 9.60907 22.366C9.37732 22.9258 9.25809 23.5257 9.25819 24.1316C9.25829 24.7374 9.37772 25.3373 9.60965 25.8969C9.84159 26.4566 10.1815 26.9651 10.6099 27.3935C11.0384 27.8218 11.547 28.1615 12.1068 28.3933C12.6665 28.625 13.2665 28.7442 13.8723 28.7441C14.4781 28.744 15.078 28.6246 15.6377 28.3927C16.1974 28.1607 16.7059 27.8208 17.1342 27.3924L21.5466 22.98L23.8472 25.2805L19.4348 29.6929C18.7049 30.4247 17.8375 31.005 16.8826 31.4004C15.9277 31.7958 14.904 31.9984 13.8704 31.9968Z"
                fill={'currentColor'}
            />
            <path
                d="M25.3191 23.8097L23.0185 21.5091L27.4309 17.0967C28.2898 16.2302 28.7705 15.0588 28.7678 13.8387C28.7652 12.6187 28.2795 11.4493 27.4169 10.5865C26.5544 9.7236 25.3852 9.2375 24.1651 9.23447C22.9451 9.23144 21.7735 9.71175 20.9067 10.5703L16.491 14.9827L14.1904 12.6811L18.6061 8.26865C20.085 6.80872 22.0815 5.99317 24.1596 6.00004C26.2378 6.00692 28.2288 6.83566 29.698 8.30534C31.1673 9.77503 31.9955 11.7663 32.0017 13.8444C32.008 15.9226 31.1919 17.9188 29.7315 19.3973L25.3191 23.8097Z"
                fill={'currentColor'}
            />
            <path
                d="M21.5467 14.1535L14.1907 21.5095L16.4916 23.8104L23.8476 16.4544L21.5467 14.1535Z"
                fill={'currentColor'}
            />
        </svg>
    )
}

LinkIcon.propTypes = {
    size: PropTypes.number,
}
