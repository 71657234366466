import styled, { css } from 'styled-components'

export const ThankYouWrapper = styled.div`
    ${({ theme: { spacing, typography, mediaQueries } }) => css`
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: ${spacing * 6}px;

        h1 {
            ${typography.fontBold}
            ${typography.textXl}
            text-align: center
        }

        h3 {
            ${typography.fontBold}
            ${typography.textLg}
            text-align: center
        }

        .subtitle-container {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            gap: ${spacing}px;
            margin-bottom: ${spacing * 6}px;
        }

        ${mediaQueries.s} {
            .subtitle-container {
                margin-bottom: 0;
            }
        }
    `}
`
