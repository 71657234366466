import { Toaster as RHToaster } from 'react-hot-toast'
import { useTheme } from 'styled-components'

export const Toaster = () => {
    const { palette } = useTheme()
    return (
        <RHToaster
            toastOptions={{
                style: {
                    backgroundColor: palette.mediumGray,
                    color: palette.white,
                },
            }}
        />
    )
}
