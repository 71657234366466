import PropTypes from 'prop-types'

export const PauseIcon = ({ size = 24 }) => {
    return (
        <svg
            width={size}
            height={size}
            viewBox="0 0 38 38"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M22.8571 32V6H30.2857V32H22.8571ZM8 32V6H15.4286V32H8Z"
                fill={'currentColor'}
            />
        </svg>
    )
}

PauseIcon.propTypes = {
    size: PropTypes.number,
}
