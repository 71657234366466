import { useNavigate } from 'react-router-dom'

import { GoogleMeetIcon } from '@/assets/icons/google-meet.jsx'
import { LinkIcon } from '@/assets/icons/link.jsx'
import { WaveFormIcon } from '@/assets/icons/waveform.jsx'
import { ZoomIcon } from '@/assets/icons/zoom.jsx'
import { HomeWrapper } from '@/pages/home/styles.js'
import * as routeNames from '@/utilities/constants/index.js'

const Home = () => {
    const navigate = useNavigate()

    return (
        <HomeWrapper>
            <h1>Summarize your meet</h1>
            <div className={'options-container'}>
                <div
                    className={'option'}
                    role={'link'}
                    onClick={() => navigate(routeNames.ROUTE_UPLOAD_AUDIO)}
                >
                    <WaveFormIcon size={38} />
                    <h2>Audio file</h2>
                    <h3>Upload a raw audio file</h3>
                </div>
                <div
                    className={'option'}
                    role={'link'}
                    onClick={() => navigate(routeNames.ROUTE_AUDIO_LINK)}
                >
                    <LinkIcon size={38} />
                    <h2>Audio link</h2>
                    <h3>Use an URL pointing to your online audio file</h3>
                </div>
                <div
                    className={'option'}
                    role={'link'}
                    onClick={() => navigate(routeNames.GOOGLE_MEET)}
                >
                    <GoogleMeetIcon size={38} />
                    <h2>Google Meet Recording</h2>
                    <h3>Use the public link to the Google Meet recording</h3>
                </div>
                <div
                    className={'option'}
                    role={'link'}
                    onClick={() => navigate(routeNames.ZOOM)}
                >
                    <ZoomIcon size={38} />
                    <h2>Zoom Recording</h2>
                    <h3>Use a secure link to the Zoom Meeting recording</h3>
                </div>
            </div>
        </HomeWrapper>
    )
}

export default Home
