import styled, { css } from 'styled-components'

export const NotFoundWrapper = styled.div`
    ${({ theme: { typography, spacing } }) => css`
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 100%;
        h1 {
            ${typography.fontBold}
            ${typography.textXl}
        }

        h2 {
            ${typography.fontSemiBold}
            ${typography.textXl}
            margin-bottom: ${spacing * 3}px
        }
    `}
`
