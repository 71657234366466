import * as RSelect from '@radix-ui/react-select'
import PropTypes from 'prop-types'

import { ChevronDownIcon } from '@/assets/icons/chevronDown.jsx'
import {
    NativeSelectWrapper,
    SelectWrapper,
    StyledContent,
    StyledNativeSelect,
} from '@/components/select/styles.js'
import { useWindowSize } from '@/hooks/useWindowSize.jsx'

const Select = ({
    name,
    value,
    items,
    onChange,
    onBlur,
    error,
    placeholder,
    className,
    icon,
}) => {
    const windowSize = useWindowSize()
    const isSm = windowSize.width < 768

    if (isSm) {
        return (
            <NativeSelectWrapper>
                <div className={'icon-container icon-left'}>{icon}</div>
                <StyledNativeSelect
                    name={name}
                    value={value}
                    onChange={e => onChange(e.target.value)}
                    onBlur={onBlur}
                >
                    <option value={''} className={'placeholder-option'}>
                        {placeholder}
                    </option>
                    {items.map(item => {
                        return (
                            <option value={item.value} key={item.value}>
                                {item.label}
                            </option>
                        )
                    })}
                </StyledNativeSelect>
                <div className={'icon-container icon-right'}>
                    <ChevronDownIcon size={14} />
                </div>
            </NativeSelectWrapper>
        )
    }

    return (
        <SelectWrapper className={className}>
            <RSelect.Root
                name={name}
                value={value}
                onValueChange={onChange}
                onOpenChange={open => {
                    if (!open) {
                        onBlur?.()
                    }
                }}
            >
                <RSelect.Trigger className={'trigger-container'}>
                    {icon}
                    <RSelect.Value placeholder={placeholder} />
                    <ChevronDownIcon size={14} />
                </RSelect.Trigger>

                <RSelect.Portal>
                    <StyledContent position={'popper'} sideOffset={4}>
                        <RSelect.Viewport className={'content-container'}>
                            <RSelect.Item
                                disabled
                                className={'item-container placeholder-option'}
                                value={''}
                            >
                                <RSelect.ItemText>
                                    {placeholder}
                                </RSelect.ItemText>
                            </RSelect.Item>
                            {items.map(item => {
                                return (
                                    <RSelect.Item
                                        className={'item-container'}
                                        value={item.value}
                                        key={item.value}
                                    >
                                        <RSelect.ItemText>
                                            {item.label}
                                        </RSelect.ItemText>
                                    </RSelect.Item>
                                )
                            })}
                        </RSelect.Viewport>
                    </StyledContent>
                </RSelect.Portal>
            </RSelect.Root>
            {error && <small>{error}</small>}
        </SelectWrapper>
    )
}

Select.propTypes = {
    items: PropTypes.arrayOf(PropTypes.object),
    value: PropTypes.string,
    name: PropTypes.string,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    error: PropTypes.string,
    placeholder: PropTypes.string,
    className: PropTypes.string,
    icon: PropTypes.node,
    variant: PropTypes.oneOf(['sm', 'lg']),
}

export default Select
