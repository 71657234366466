import PropTypes from 'prop-types'

export const ZoomIcon = ({ size = 24 }) => {
    return (
        <svg
            width={size}
            height={size}
            viewBox="0 0 38 38"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M6 13.6526V22.4006C6.00779 24.3787 7.76647 25.9699 9.91194 25.9634H23.7906C24.1839 25.9634 24.5032 25.6714 24.5032 25.3171V16.5691C24.4928 14.5923 22.7341 12.9998 20.5913 13.0076H6.70996C6.31799 13.0076 6 13.2983 6 13.6526ZM25.3858 17.0662L31.1174 13.2204C31.6145 12.8414 32 12.9362 32 13.6215V25.3495C32 26.1296 31.5289 26.0348 31.1174 25.7506L25.3858 21.9126V17.0662Z"
                fill="currentColor"
            />
        </svg>
    )
}

ZoomIcon.propTypes = {
    size: PropTypes.number,
}
