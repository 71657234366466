import PropTypes from 'prop-types'
import { forwardRef } from 'react'

import { StyledButton } from '@/components/aixplain-button/styles.js'
const AixplainButton = forwardRef(function Component(props, ref) {
    return <StyledButton {...props} ref={ref} />
})

AixplainButton.propTypes = {
    type: PropTypes.oneOf(['button', 'submit']),
    onClick: PropTypes.func,
    disabled: PropTypes.bool,
    variant: PropTypes.oneOf(['primary', 'success']),
    className: PropTypes.string,
}

export default AixplainButton
