import { yupResolver } from '@hookform/resolvers/yup'
import PropTypes from 'prop-types'
import { useEffect } from 'react'
import {
    Controller,
    FormProvider,
    useForm,
    useFormContext,
} from 'react-hook-form'

import { TranslateIcon } from '@/assets/icons/translate.jsx'
import AixplainButton from '@/components/aixplain-button/AixplainButton.jsx'
import Select from '@/components/select/Select.jsx'
import Spinner from '@/components/spinner/Spinner.jsx'
import TextField from '@/components/textfield/TextField.jsx'
import TitleAndSteps from '@/components/title-and-steps/TitleAndSteps.jsx'
import Tooltip from '@/components/tooltip/Tooltip.jsx'
import { useLanguages } from '@/services/queries/useLanguages.js'

import {
    MainContentWrapper,
    SubmitSectionWrapper,
    SummarizationTemplateForm,
} from './styles.js'

const SummarizationContent = ({
    title,
    steps,
    children,
    onSubmit,
    additionalContent,
    isLoading,
}) => {
    const {
        handleSubmit,
        control,
        formState: { isValid },
        setValue,
        watch,
    } = useFormContext()

    const { data, isLoading: isLoadingLanguages } = useLanguages()
    const languageItems =
        data?.map(lang => ({
            label: lang.name,
            value: lang.id.toString(),
        })) || []

    const audioLanguage = watch('language_id')

    useEffect(() => {
        if (!data || audioLanguage) {
            return
        }
        //Fill language field with english id
        setValue(
            'language_id',
            data
                .find(lang_item => (lang_item.key_name = 'en'))
                ?.id.toString() || '1'
        )
    }, [data, audioLanguage, setValue])

    return (
        <SummarizationTemplateForm onSubmit={handleSubmit(onSubmit)}>
            <TitleAndSteps title={title} steps={steps} />

            <div className={'main-section-container'}>
                {isLoadingLanguages || !audioLanguage ? (
                    <Spinner size={18} className={'language-select-spinner'} />
                ) : (
                    <Controller
                        control={control}
                        name="language_id"
                        render={({
                            field: { name, value, onChange, onBlur },
                        }) => {
                            return (
                                <Select
                                    name={name}
                                    placeholder={'Select audio language'}
                                    icon={<TranslateIcon size={24} />}
                                    className={'language-select'}
                                    value={value}
                                    onChange={onChange}
                                    onBlur={onBlur}
                                    items={languageItems}
                                />
                            )
                        }}
                    />
                )}

                <MainContentWrapper>{children}</MainContentWrapper>

                {additionalContent}
            </div>

            <SubmitSectionWrapper>
                <Controller
                    control={control}
                    name="email"
                    render={({
                        field: { name, value, onChange, onBlur },
                        fieldState: { error },
                    }) => {
                        return (
                            <div className={'email-test-field-container'}>
                                <TextField
                                    name={name}
                                    value={value}
                                    onChange={onChange}
                                    onBlur={onBlur}
                                    error={error?.message}
                                    placeholder={'Your email'}
                                />
                            </div>
                        )
                    }}
                />
                <Tooltip
                    triggerAsChild={true}
                    open={audioLanguage ? false : undefined}
                    content={'Select a language first'}
                >
                    <AixplainButton
                        className={'submit-button'}
                        type="submit"
                        disabled={!isValid || isLoading}
                        variant={'success'}
                    >
                        {!isLoading ? 'Summarize it!' : <Spinner size={18} />}
                    </AixplainButton>
                </Tooltip>
            </SubmitSectionWrapper>
        </SummarizationTemplateForm>
    )
}

const contentPropTypes = {
    children: PropTypes.node.isRequired,
    additionalContent: PropTypes.node,
    title: PropTypes.string.isRequired,
    steps: PropTypes.arrayOf(PropTypes.string).isRequired,
    onSubmit: PropTypes.func,
    isLoading: PropTypes.bool,
}

SummarizationContent.propTypes = contentPropTypes

const SummarizationTemplate = ({
    formSchema,
    children,
    defaultValues = {},
    ...rest
}) => {
    const methods = useForm({
        mode: 'onChange',
        resolver: yupResolver(formSchema),
        defaultValues: {
            ...defaultValues,
            email: '',
            language_id: '',
        },
    })

    return (
        <FormProvider {...methods}>
            <SummarizationContent {...rest}>{children}</SummarizationContent>
        </FormProvider>
    )
}

SummarizationTemplate.propTypes = {
    children: PropTypes.node.isRequired,
    formSchema: PropTypes.object,
    defaultValues: PropTypes.object,
    ...contentPropTypes,
}
export default SummarizationTemplate
