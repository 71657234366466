import PropTypes from 'prop-types'

export const XIcon = ({ size = 24 }) => {
    return (
        <svg
            width={size}
            height={size}
            viewBox="0 0 14 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M14 0.924513L13.0754 0L7 6.07541L0.924595 0L0 0.924513L6.07545 6.99996L0 13.0754L0.924595 13.9999L7 7.92451L13.0754 13.9999L14 13.0754L7.92455 6.99996L14 0.924513Z"
                fill="currentColor"
            />
        </svg>
    )
}

XIcon.propTypes = {
    size: PropTypes.number,
}
