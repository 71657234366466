import PropTypes from 'prop-types'
export const TranslateIcon = ({ size = 24 }) => {
    return (
        <svg
            width={size}
            height={size}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M12.6493 14.1766L10.7534 12.3031L10.7758 12.2807C12.0746 10.8327 13.0001 9.1682 13.545 7.40667H15.732V5.91384H10.5071V4.42102H9.01431V5.91384H3.78943V7.3992H12.1268C11.6267 8.83978 10.8356 10.2057 9.76072 11.4C9.06656 10.6312 8.49182 9.78772 8.03651 8.89949H6.54369C7.08857 10.1161 7.83498 11.2656 8.76799 12.3031L4.96876 16.0501L6.02866 17.11L9.76072 13.378L12.0821 15.6993L12.6493 14.1766ZM16.8516 10.3923H15.3588L12 19.3492H13.4928L14.3288 17.11H17.8742L18.7177 19.3492H20.2105L16.8516 10.3923ZM14.896 15.6172L16.1052 12.3852L17.3144 15.6172H14.896Z"
                fill={'currentColor'}
            />
        </svg>
    )
}

TranslateIcon.propTypes = {
    size: PropTypes.number,
}
