import { useLocation, useNavigate } from 'react-router-dom'

import { ChevronDownIcon } from '@/assets/icons/chevronDown.jsx'
import { GoogleMeetIcon2 } from '@/assets/icons/google-meet-2.jsx'
import { GoogleMeetIcon } from '@/assets/icons/google-meet.jsx'
import { LinkIcon } from '@/assets/icons/link.jsx'
import { Logo } from '@/assets/icons/logo.jsx'
import { WaveFormIcon } from '@/assets/icons/waveform.jsx'
import { XIcon } from '@/assets/icons/x.jsx'
import { ZoomIcon2 } from '@/assets/icons/zoom-2.jsx'
import { ZoomIcon } from '@/assets/icons/zoom.jsx'
import DropdownMenu from '@/components/dropdown-menu/DropdownMenu.jsx'
import DownloadSummaryAnchor from '@/components/header/DownloadSummaryAnchor.jsx'
import {
    HeaderContentWrapper,
    HeaderWrapper,
    StyledMenuTrigger,
} from '@/components/header/styles.js'
import * as routeNames from '@/utilities/constants/index.js'

const menuTriggerByLocation = {
    [routeNames.ROUTE_AUDIO_LINK]: (
        <StyledMenuTrigger>
            <LinkIcon size={38} />
            <span className={'title'}>Audio link</span>
            <ChevronDownIcon size={20} />
        </StyledMenuTrigger>
    ),
    [routeNames.ROUTE_UPLOAD_AUDIO]: (
        <StyledMenuTrigger>
            <WaveFormIcon size={38} />
            <span className={'title'}>Audio file</span>
            <ChevronDownIcon size={20} />
        </StyledMenuTrigger>
    ),
    [routeNames.GOOGLE_MEET]: (
        <StyledMenuTrigger>
            <GoogleMeetIcon size={38} />
            <span className={'title'}>Google Meet Recording</span>
            <ChevronDownIcon size={20} />
        </StyledMenuTrigger>
    ),
    [routeNames.ZOOM]: (
        <StyledMenuTrigger>
            <ZoomIcon size={38} />
            <span className={'title'}>Zoom Recording</span>
            <ChevronDownIcon size={20} />
        </StyledMenuTrigger>
    ),
}

const featureIconByLocation = {
    [routeNames.GOOGLE_MEET]: <GoogleMeetIcon2 size={40} />,
    [routeNames.ZOOM]: <ZoomIcon2 />,
}

const Header = () => {
    const navigate = useNavigate()
    const location = useLocation()
    const menuTrigger = menuTriggerByLocation[location.pathname]
    const featureIcon = featureIconByLocation[location.pathname]
    const isDownloadPossible = location.pathname === routeNames.SUMMARY

    return (
        <HeaderWrapper>
            <HeaderContentWrapper>
                <div
                    className={'logo-container'}
                    role={'link'}
                    onClick={() => navigate('/')}
                >
                    <Logo />
                    {featureIcon && (
                        <>
                            <XIcon size={14} />
                            {featureIcon}
                        </>
                    )}
                </div>
                {menuTrigger && (
                    <DropdownMenu
                        actions={[
                            {
                                icon: <WaveFormIcon size={38} />,
                                label: 'Audio file',
                                onSelect: () =>
                                    navigate(routeNames.ROUTE_UPLOAD_AUDIO),
                            },
                            {
                                icon: <LinkIcon size={38} />,
                                label: 'Audio link',
                                onSelect: () =>
                                    navigate(routeNames.ROUTE_AUDIO_LINK),
                            },
                            {
                                icon: <GoogleMeetIcon size={38} />,
                                label: 'Google Meet Recording',
                                onSelect: () =>
                                    navigate(routeNames.GOOGLE_MEET),
                            },
                            {
                                icon: <ZoomIcon size={38} />,
                                label: 'Zoom Recording',
                                onSelect: () => navigate(routeNames.ZOOM),
                            },
                        ]}
                    >
                        {menuTrigger}
                    </DropdownMenu>
                )}
                {isDownloadPossible && <DownloadSummaryAnchor />}
            </HeaderContentWrapper>
        </HeaderWrapper>
    )
}

export default Header
