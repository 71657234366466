import { useRef } from 'react'
import { useSearchParams } from 'react-router-dom'

import { DownloadIcon } from '@/assets/icons/download.jsx'
import { StyledDownloadButton } from '@/components/header/styles.js'
import { useFileSummarization } from '@/services/queries/useFileSummarization.js'

const DownloadSummaryAnchor = () => {
    const [params] = useSearchParams()
    const anchorRef = useRef()
    const fileId = params.get('file_id')
    const password = params.get('password')
    if (!fileId || !password) {
        throw new Error('file_id or password param missing')
    }
    const { data, isLoading, isError } = useFileSummarization(fileId, password)
    if (isLoading || isError) {
        return null
    }
    return (
        <>
            <a
                ref={anchorRef}
                href={data.download_url}
                download={''}
                target="_self"
                style={{ display: 'none' }}
            />
            <StyledDownloadButton onClick={() => anchorRef.current?.click()}>
                <DownloadIcon size={18} />
                <span className={'text'}>Download</span>
            </StyledDownloadButton>
        </>
    )
}

export default DownloadSummaryAnchor
