import PropTypes from 'prop-types'

import { StyledSvg } from './styles'

const Spinner = ({ className, size = 50, color = 'white' }) => {
    return (
        <>
            <StyledSvg
                className={className}
                width={size}
                height={size}
                viewBox="0 0 50 50"
            >
                <circle
                    cx="25"
                    cy="25"
                    r="20"
                    fill="none"
                    stroke={color}
                    strokeWidth="3"
                />
            </StyledSvg>
        </>
    )
}

export default Spinner

Spinner.propTypes = {
    className: PropTypes.string,
    size: PropTypes.number,
    color: PropTypes.string,
}
