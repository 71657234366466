import { useNavigate } from 'react-router-dom'

import AixplainButton from '@/components/aixplain-button/AixplainButton.jsx'
import { NotFoundWrapper } from '@/pages/not-found/styles.jsx'

const NotFound = () => {
    const navigate = useNavigate()
    return (
        <NotFoundWrapper>
            <h1>404!</h1>
            <h2>Page not found</h2>
            <AixplainButton
                variant={'primary'}
                role={'link'}
                onClick={() => navigate('/')}
            >
                Back to home
            </AixplainButton>
        </NotFoundWrapper>
    )
}

export default NotFound
