import PropTypes from 'prop-types'

export const WaveFormIcon = ({ size = 24 }) => {
    return (
        <svg
            width={size}
            height={size}
            viewBox="0 0 38 38"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M23.25 13.5833H26.5V24.4167H23.25V13.5833ZM17.8333 32H21.0833V6H17.8333V32ZM28.6667 10.3333V27.6667H31.9167V10.3333H28.6667ZM12.4167 26.5833H15.6667V11.4167H12.4167V26.5833ZM7 22.25H10.25V15.75H7V22.25Z"
                fill="currentColor"
            />
        </svg>
    )
}

WaveFormIcon.propTypes = { size: PropTypes.number }
