import PropTypes from 'prop-types'

import { TitleAndStepsWrapper } from '@/components/title-and-steps/styles.js'

const TitleAndSteps = ({ title, steps }) => {
    return (
        <TitleAndStepsWrapper>
            <h1>{title}</h1>
            <div className={'steps-container'}>
                {steps.map((step, index) => (
                    <p key={index}>{`${index + 1}. ${step}`}</p>
                ))}
            </div>
        </TitleAndStepsWrapper>
    )
}

TitleAndSteps.propTypes = {
    title: PropTypes.string.isRequired,
    steps: PropTypes.arrayOf(PropTypes.string).isRequired,
}
export default TitleAndSteps
