import styled, { css } from 'styled-components'

import AixplainButton from '@/components/aixplain-button/AixplainButton.jsx'
import { palette } from '@/theme/palette.js'

export const SummarizationsWrapper = styled.div`
    ${({ theme: { spacing, mediaQueries, typography } }) => css`
        padding-top: ${spacing * 10}px;

        .table-container {
            width: 100%;
        }

        .table-row {
            width: 100%;
            gap: ${spacing / 2}px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-bottom: 1px solid ${palette.darkerGray};
        }

        .cell {
            padding: ${spacing * 2}px 0;
        }

        .header-cell {
            ${typography.fontSemiBold}
            ${typography.textSm}
        }

        .upload-date-cell {
            min-width: 180px;
        }

        .status-cell {
            min-width: 120px;
            display: flex;
            align-items: center;
            gap: ${spacing / 2}px;
        }

        .action-cell {
            min-width: 100px;
        }

        .name-cell {
            flex-grow: 1;
            overflow: hidden;
            text-overflow: ellipsis;
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: ${spacing}px;

            .title-container {
                flex-grow: 1;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
        }

        ${mediaQueries.s} {
            padding-top: ${spacing * 4}px;
        }

        ${mediaQueries.m} {
            .upload-date-cell {
                display: none;
            }

            .action-cell {
                min-width: 60px;
            }
            .status-name {
                display: none;
            }
            .status-cell {
                min-width: 60px;
                justify-content: center;
            }
        }
    `}
`

export const IconWrapper = styled.div`
    ${({ theme: { palette } }) => css`
        display: grid;
        place-items: center;

        &.success {
            color: ${palette.success};
        }

        &.error {
            color: ${palette.danger};
        }

        &.warning {
            color: ${palette.warning};
        }
    `}
`

export const StyledViewButton = styled(AixplainButton)`
    ${({ theme: { palette, mediaQueries } }) => css`
        height: 32px;
        width: 96px;
        padding: 0;
        display: grid;
        place-items: center;
        background: ${palette.white};
        border: 1px solid ${palette.white};
        color: ${palette.background};

        &:disabled {
            color: ${palette.lighterGray};
        }

        ${mediaQueries.m} {
            width: 60px;
        }
    `}
`
