import PropTypes from 'prop-types'
export const UploadIcon = ({ size = 24 }) => {
    return (
        <svg
            width={size}
            height={size}
            viewBox="0 0 38 39"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M9.16667 31.8333C8.29584 31.8333 7.55009 31.523 6.92942 30.9023C6.30875 30.2817 5.99895 29.5364 6 28.6667V23.9167H9.16667V28.6667H28.1667V23.9167H31.3333V28.6667C31.3333 29.5375 31.023 30.2832 30.4023 30.9039C29.7817 31.5246 29.0364 31.8344 28.1667 31.8333H9.16667ZM17.0833 25.5V12.5958L12.9667 16.7125L10.75 14.4167L18.6667 6.5L26.5833 14.4167L24.3667 16.7125L20.25 12.5958V25.5H17.0833Z"
                fill={'currentColor'}
            />
        </svg>
    )
}

UploadIcon.propTypes = {
    size: PropTypes.number,
}
