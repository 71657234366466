import * as RDropdownMenu from '@radix-ui/react-dropdown-menu'
import styled, { css } from 'styled-components'

export const StyledTrigger = styled(RDropdownMenu.Trigger)`
    cursor: pointer;
`

export const StyledDropdownContent = styled(RDropdownMenu.Content)`
    ${({ theme: { spacing, palette, typography } }) => css`
        background: ${palette.darkGray};
        border-radius: 10px;
        padding: ${spacing * 2}px;
        display: flex;
        flex-direction: column;
        align-content: start;
        justify-content: center;
        gap: ${spacing * 2}px;

        .dropdown-item {
            display: flex;
            justify-content: start;
            align-items: center;
            gap: ${spacing}px;
            cursor: pointer;
            border-radius: 10px;
            ${typography.fontSemiBold}
            ${typography.textLg}

            &:hover {
                color: ${palette.lightGray};

                & > svg {
                    fill: ${palette.lightGray};
                }
            }
        }
    `}
`
