import { Controller, useFormContext } from 'react-hook-form'

import TextField from '@/components/textfield/TextField.jsx'

import { AudioLinkFormWrapper } from './styles.js'

const AudioLinkForm = () => {
    const { control } = useFormContext()

    return (
        <AudioLinkFormWrapper>
            <Controller
                control={control}
                name="url"
                render={({
                    field: { name, value, onChange, onBlur },
                    fieldState: { error },
                }) => {
                    return (
                        <div className={'link-text-field-container'}>
                            <TextField
                                textFieldClassName={'link-text-field'}
                                placeholder={
                                    'Paste your meeting recording link here...'
                                }
                                name={name}
                                value={value}
                                onChange={onChange}
                                onBlur={onBlur}
                                error={error?.message}
                            />
                        </div>
                    )
                }}
            />
        </AudioLinkFormWrapper>
    )
}

export default AudioLinkForm
