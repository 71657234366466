import * as RSelect from '@radix-ui/react-select'
import styled, { css } from 'styled-components'

export const SelectWrapper = styled.div`
    ${({ theme: { spacing, palette, typography } }) => css`
        .trigger-container {
            cursor: pointer;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            gap: ${spacing}px;
            ${typography.fontRegular}
            ${typography.textMd}
        }

        .content-container {
            background: ${palette.darkGray};
            border-radius: 10px;
            padding-right: ${spacing * 2}px;
            display: flex;
            flex-direction: column;
            align-content: start;
            justify-content: center;
            gap: ${spacing * 2}px;
        }
    `}
`

export const StyledContent = styled(RSelect.Content)`
    ${({ theme: { spacing, palette, typography } }) => css`
        min-width: var(--radix-select-trigger-width);
        background: ${palette.darkGray};
        border-radius: 10px;
        padding: ${spacing * 2}px;
        display: flex;
        flex-direction: column;
        align-content: start;
        justify-content: center;
        gap: ${spacing * 2}px;

        .item-container {
            cursor: pointer;
            border-radius: 10px;
            padding: ${spacing}px 0;
            ${typography.fontRegular}
            ${typography.textMd}

            &:hover {
                color: ${palette.lightGray};
            }
            &.placeholder-option {
                display: none;
            }
        }
    `}
`

export const NativeSelectWrapper = styled.div`
    ${({ theme: { spacing } }) => css`
        cursor: pointer;
        position: relative;
        z-index: 1;
        margin-left: auto;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        gap: ${spacing}px;

        .icon-container {
            display: grid;
            place-items: center;
            position: absolute;
            pointer-events: none;
        }

        .icon-left {
            left: 0;
        }

        .icon-right {
            right: 0;
        }
    `}
`

export const StyledNativeSelect = styled.select`
    ${({ theme: { typography, spacing } }) => css`
        ${typography.fontRegular}
        ${typography.textMd}
        padding-left: ${spacing * 4}px;
        padding-right: ${spacing * 3}px;

        .placeholder-option {
            display: none;
        }
    `}
`
