import styled, { css } from 'styled-components'

export const ZoomRecordingFormWrapper = styled.div`
    ${({ theme: { spacing } }) => css`
        width: 100%;
        gap: ${spacing * 2}px;
        display: flex;
        flex-direction: column;
        .link-text-field-container {
            width: 90%;
            max-width: 590px;
            margin: auto;
        }
        .link-text-field {
            height: 40px;
            width: 100%;
            max-width: 590px;
        }
    `}
`
