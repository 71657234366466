import PropTypes from 'prop-types'
export const CrossIcon = ({ size = 24 }) => {
    return (
        <svg
            width={size}
            height={size}
            viewBox="0 0 17 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M12.7 4.30664C12.44 4.04664 12.02 4.04664 11.76 4.30664L8.49998 7.55997L5.23998 4.29997C4.97998 4.03997 4.55998 4.03997 4.29998 4.29997C4.03998 4.55997 4.03998 4.97997 4.29998 5.23997L7.55998 8.49997L4.29998 11.76C4.03998 12.02 4.03998 12.44 4.29998 12.7C4.55998 12.96 4.97998 12.96 5.23998 12.7L8.49998 9.43997L11.76 12.7C12.02 12.96 12.44 12.96 12.7 12.7C12.96 12.44 12.96 12.02 12.7 11.76L9.43998 8.49997L12.7 5.23997C12.9533 4.98664 12.9533 4.55997 12.7 4.30664Z"
                fill={'currentColor'}
            />
        </svg>
    )
}

CrossIcon.propTypes = {
    size: PropTypes.number,
}
