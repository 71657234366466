import { Outlet, useLocation } from 'react-router-dom'

import Header from '@/components/header/Header.jsx'

import {
    ContentWrapper,
    StyledGithubRibbonAnchor,
    StyledRoot,
} from './styles.js'
const PublicLayout = () => {
    const location = useLocation()
    return (
        <StyledRoot>
            <Header />
            <ContentWrapper key={location.pathname}>
                <StyledGithubRibbonAnchor
                    href={import.meta.env.VITE_GITHUB_REPO_URL}
                >
                    <img
                        decoding="async"
                        loading="lazy"
                        width="149"
                        height="149"
                        src="https://github.blog/wp-content/uploads/2008/12/forkme_left_orange_ff7600.png?resize=149%2C149"
                        className="attachment-full size-full"
                        alt="Fork me on GitHub"
                        data-recalc-dims="1"
                    />
                </StyledGithubRibbonAnchor>
                <div className={'content'}>
                    <Outlet />
                </div>
            </ContentWrapper>
        </StyledRoot>
    )
}

export default PublicLayout
