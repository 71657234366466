import { Controller, useFormContext } from 'react-hook-form'

import Uploader from '@/components/uploader/Uploader.jsx'

const UploadAudioForm = () => {
    const { control } = useFormContext()

    return (
        <Controller
            control={control}
            name="file"
            render={({
                field: { name, value, onChange },
                fieldState: { error },
            }) => {
                return (
                    <Uploader
                        name={name}
                        value={value}
                        onChange={onChange}
                        error={error?.message}
                    />
                )
            }}
        />
    )
}

export default UploadAudioForm
