import PropTypes from 'prop-types'

export const CheckIcon = ({ size = 24 }) => {
    return (
        <svg
            width={size}
            height={size}
            viewBox="0 0 38 39"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M19 6.5C11.824 6.5 6 12.324 6 19.5C6 26.676 11.824 32.5 19 32.5C26.176 32.5 32 26.676 32 19.5C32 12.324 26.176 6.5 19 6.5ZM19 29.9C13.267 29.9 8.6 25.233 8.6 19.5C8.6 13.767 13.267 9.1 19 9.1C24.733 9.1 29.4 13.767 29.4 19.5C29.4 25.233 24.733 29.9 19 29.9ZM24.967 13.754L16.4 22.321L13.033 18.967L11.2 20.8L16.4 26L26.8 15.6L24.967 13.754Z"
                fill={'currentColor'}
            />
        </svg>
    )
}
CheckIcon.propTypes = {
    size: PropTypes.number,
}
