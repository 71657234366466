import PropTypes from 'prop-types'

export const AddFileIcon = ({ size = 24 }) => {
    return (
        <svg
            width={size}
            height={size}
            viewBox="0 0 22 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0_51_852)">
                <path
                    d="M12.9614 0.75H5.3125C4.66603 0.75 4.04605 1.00681 3.58893 1.46393C3.13181 1.92105 2.875 2.54103 2.875 3.1875V20.25H19.125V6.91362L12.9614 0.75ZM13.4375 3.52387L16.3511 6.4375H13.4375V3.52387ZM4.5 18.625V3.1875C4.5 2.97201 4.5856 2.76535 4.73798 2.61298C4.89035 2.4606 5.09701 2.375 5.3125 2.375H11.8125V8.0625H17.5V18.625H4.5ZM11.8125 12.125H14.25V13.75H11.8125V16.1875H10.1875V13.75H7.75V12.125H10.1875V9.6875H11.8125V12.125Z"
                    fill={'currentColor'}
                />
            </g>
            <defs>
                <clipPath id="clip0_51_852">
                    <rect
                        width="20.75"
                        height="19.5"
                        fill={'currentColor'}
                        transform="translate(0.625 0.75)"
                    />
                </clipPath>
            </defs>
        </svg>
    )
}

AddFileIcon.propTypes = {
    size: PropTypes.number,
}
