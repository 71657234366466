import { Controller, useFormContext } from 'react-hook-form'

import TextField from '@/components/textfield/TextField.jsx'
import { ZoomRecordingFormWrapper } from '@/pages/zoom-recording/styles.js'

const ZoomRecordingForm = () => {
    const { control } = useFormContext()
    return (
        <ZoomRecordingFormWrapper>
            <Controller
                control={control}
                name="zoom_link"
                render={({
                    field: { name, value, onChange, onBlur },
                    fieldState: { error },
                }) => {
                    return (
                        <div className={'link-text-field-container'}>
                            <TextField
                                textFieldClassName={'link-text-field'}
                                placeholder={'Zoom recording url'}
                                name={name}
                                value={value}
                                onChange={onChange}
                                onBlur={onBlur}
                                error={error?.message}
                            />
                        </div>
                    )
                }}
            />
            <Controller
                control={control}
                name="passcode"
                render={({
                    field: { name, value, onChange, onBlur },
                    fieldState: { error },
                }) => {
                    return (
                        <div className={'link-text-field-container'}>
                            <TextField
                                textFieldClassName={'link-text-field'}
                                placeholder={'Passcode'}
                                name={name}
                                value={value}
                                onChange={onChange}
                                onBlur={onBlur}
                                error={error?.message}
                            />
                        </div>
                    )
                }}
            />
        </ZoomRecordingFormWrapper>
    )
}
export default ZoomRecordingForm
