import { useMutation, useQueryClient } from '@tanstack/react-query'
import toast from 'react-hot-toast'
import { useNavigate } from 'react-router-dom'
import * as yup from 'yup'

import GoogleMeetRecordingForm from '@/pages/google-meet-recording/GoogleMeetRecordingForm.jsx'
import { summarizeGoogleMeetLink } from '@/services/summarization.js'
import * as routeNames from '@/utilities/constants/index.js'

import SummarizationTemplate from '../../templates/summarization/SummarizationTemplate.jsx'

const formSchema = yup.object().shape({
    email: yup
        .string()
        .email('Enter a valid email')
        .required('This field is required'),

    google_meet_link: yup
        .string()
        .url('Enter a valid url')
        .matches(
            /^https:\/\/drive\.google\.com\/file\/d\/[a-zA-Z0-9_-]+/,
            'Enter a valid google meet recording link'
        )
        .required('This field is required'),
    language_id: yup.number().required('This field is requires'),
})

const GoogleMeetRecording = () => {
    const navigate = useNavigate()
    const queryClient = useQueryClient()

    const { mutate, isLoading } = useMutation({
        mutationFn: payload => summarizeGoogleMeetLink(payload),
        onSuccess: () => {
            queryClient.invalidateQueries(['user-summarizations']).then(() => {
                navigate({
                    pathname: routeNames.THANK_YOU,
                })
            })
        },
        onError: error => {
            toast.error('Ops! Something went wrong, try again later.')
            console.error(error)
        },
    })

    const onSubmit = values => {
        mutate({ ...values, language_id: parseInt(values.language_id) })
    }
    return (
        <SummarizationTemplate
            isLoading={isLoading}
            title={'Google Meet Recording'}
            steps={[
                'Record your Google Meet',
                'Share the recording to “Anyone with the link”',
                'Select the recording’s language',
                `Press "Summarize it"`,
            ]}
            defaultValues={{ google_meet_link: '' }}
            formSchema={formSchema}
            onSubmit={onSubmit}
        >
            <GoogleMeetRecordingForm />
        </SummarizationTemplate>
    )
}

export default GoogleMeetRecording
