import * as RTooltip from '@radix-ui/react-tooltip'
import styled, { css } from 'styled-components'

export const StyledTooltipContent = styled(RTooltip.Content)`
    ${({ theme: { spacing, palette, typography } }) => css`
        padding: ${spacing}px ${spacing * 2}px;
        border-radius: 10px;
        background-color: ${palette.mediumGray};
        ${typography.textXs}
    `}
`
