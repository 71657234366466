import PropTypes from 'prop-types'

export const AlertIcon = ({ size = 24 }) => {
    return (
        <svg
            width={size}
            height={size}
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M8.38415 12.0786H9.61572V8.3839H8.38415V12.0786ZM8.99994 7.15232C9.17441 7.15232 9.32076 7.09321 9.43899 6.97498C9.55722 6.85674 9.61614 6.7106 9.61572 6.53653C9.61572 6.36206 9.55661 6.21571 9.43838 6.09748C9.32015 5.97924 9.174 5.92033 8.99994 5.92074C8.82546 5.92074 8.67911 5.97986 8.56088 6.09809C8.44265 6.21632 8.38374 6.36247 8.38415 6.53653C8.38415 6.71101 8.44326 6.85736 8.56149 6.97559C8.67972 7.09382 8.82587 7.15273 8.99994 7.15232ZM8.99994 15.1576C8.14809 15.1576 7.34757 14.9958 6.59836 14.6723C5.84915 14.3488 5.19744 13.9102 4.64323 13.3564C4.08901 12.8022 3.65037 12.1505 3.32728 11.4013C3.0042 10.6521 2.84245 9.85153 2.84204 8.99969C2.84204 8.14785 3.00379 7.34732 3.32728 6.59811C3.65078 5.8489 4.08943 5.19719 4.64323 4.64298C5.19744 4.08877 5.84915 3.65012 6.59836 3.32704C7.34757 3.00395 8.14809 2.84221 8.99994 2.8418C9.85178 2.8418 10.6523 3.00354 11.4015 3.32704C12.1507 3.65053 12.8024 4.08918 13.3566 4.64298C13.9109 5.19719 14.3497 5.8489 14.6732 6.59811C14.9967 7.34732 15.1582 8.14785 15.1578 8.99969C15.1578 9.85153 14.9961 10.6521 14.6726 11.4013C14.3491 12.1505 13.9104 12.8022 13.3566 13.3564C12.8024 13.9106 12.1507 14.3495 11.4015 14.673C10.6523 14.9965 9.85178 15.158 8.99994 15.1576ZM8.99994 13.926C10.3752 13.926 11.5401 13.4488 12.4945 12.4943C13.449 11.5398 13.9263 10.375 13.9263 8.99969C13.9263 7.62443 13.449 6.45956 12.4945 5.50509C11.5401 4.55061 10.3752 4.07338 8.99994 4.07338C7.62467 4.07338 6.4598 4.55061 5.50533 5.50509C4.55086 6.45956 4.07362 7.62443 4.07362 8.99969C4.07362 10.375 4.55086 11.5398 5.50533 12.4943C6.4598 13.4488 7.62467 13.926 8.99994 13.926Z"
                fill="currentColor"
            />
        </svg>
    )
}
AlertIcon.propTypes = {
    size: PropTypes.number,
}
