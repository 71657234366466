import dayjs from 'dayjs'
import { useNavigate, useSearchParams } from 'react-router-dom'

import { AlertIcon } from '@/assets/icons/alert.jsx'
import { CheckIcon } from '@/assets/icons/check.jsx'
import { GoogleMeetIcon } from '@/assets/icons/google-meet.jsx'
import { LinkIcon } from '@/assets/icons/link.jsx'
import { WaveFormIcon } from '@/assets/icons/waveform.jsx'
import { ZoomIcon } from '@/assets/icons/zoom.jsx'
import {
    ErrorState,
    LoadingState,
} from '@/components/page-states/PageState.jsx'
import {
    IconWrapper,
    StyledViewButton,
    SummarizationsWrapper,
} from '@/pages/summatizations/styles.js'
import { useUserSummarizations } from '@/services/queries/useUserSummarizations.js'
import * as routeNames from '@/utilities/constants/index.js'
import { titleGetterBySummaryType } from '@/utilities/helpers.js'

const iconBySummarizationType = {
    files_url: (
        <IconWrapper>
            <LinkIcon size={32} />
        </IconWrapper>
    ),
    files_uploaded: (
        <IconWrapper>
            <WaveFormIcon size={32} />
        </IconWrapper>
    ),
    files_google_meet: (
        <IconWrapper>
            <GoogleMeetIcon size={32} />
        </IconWrapper>
    ),
    files_zoom: (
        <IconWrapper>
            <ZoomIcon size={32} />
        </IconWrapper>
    ),
}

const statusMap = {
    pending: {
        label: 'In progress',
        icon: (
            <IconWrapper className={'warning'}>
                <AlertIcon size={18} />
            </IconWrapper>
        ),
    },
    success: {
        label: 'Completed',
        icon: (
            <IconWrapper className={'success'}>
                <CheckIcon size={18} />
            </IconWrapper>
        ),
    },
    error: {
        label: 'Failed',
        icon: (
            <IconWrapper className={'error'}>
                <AlertIcon size={18} />
            </IconWrapper>
        ),
    },
}

const Summarizations = () => {
    const [params] = useSearchParams()
    const navigate = useNavigate()

    const userId = params.get('userId')
    const password = params.get('password')

    if (!userId || !password) {
        throw new Error('User param is missing!')
    }

    const { data, isLoading, isError } = useUserSummarizations(
        parseInt(userId),
        password,
        { refetchInterval: 1000 * 60 }
    )

    if (isLoading) {
        return <LoadingState />
    }

    if (isError) {
        return <ErrorState />
    }

    return (
        <SummarizationsWrapper>
            <div className={'table-container'}>
                <div className={'table-row'}>
                    <div className={'cell header-cell name-cell'}>Name</div>
                    <div className={'cell header-cell upload-date-cell'}>
                        Upload
                    </div>
                    <div className={'cell header-cell status-cell'}>Status</div>
                    <div className={'cell header-cell action-cell'}></div>
                </div>
                {data?.map(summary => (
                    <div key={summary.id} className={'table-row'}>
                        <div className={'cell  name-cell'}>
                            {iconBySummarizationType[summary.type]}
                            <span className={'title-container'}>
                                {titleGetterBySummaryType(
                                    summary,
                                    summary.type
                                )}
                            </span>
                        </div>
                        <div className={'cell  upload-date-cell'}>
                            {dayjs(summary.created_at).format(
                                'h:mm a MM/DD/YYYY'
                            )}
                        </div>
                        <div className={'cell  status-cell'}>
                            {statusMap[summary.status].icon}
                            <div className={'status-name'}>
                                {statusMap[summary.status].label}
                            </div>
                        </div>
                        <div className={'cell  action-cell'}>
                            <StyledViewButton
                                role={'link'}
                                onClick={() =>
                                    navigate({
                                        pathname: routeNames.SUMMARY,
                                        search: `?file_id=${summary.uuid}&password=${summary.password}`,
                                    })
                                }
                                disabled={summary.status !== 'success'}
                            >
                                View
                            </StyledViewButton>
                        </div>
                    </div>
                ))}
            </div>
        </SummarizationsWrapper>
    )
}

export default Summarizations
