import { useNavigate } from 'react-router-dom'

import AixplainButton from '@/components/aixplain-button/AixplainButton.jsx'
import { ThankYouWrapper } from '@/pages/thank-you/styles.js'

const ThankYou = () => {
    const navigate = useNavigate()

    return (
        <ThankYouWrapper>
            <h1>Thank you for your submission!</h1>
            <div className={'subtitle-container'}>
                <h3>Your recording will be processed automatically.</h3>
                <h3>You will receive an email with your summary.</h3>
            </div>
            <AixplainButton
                variant={'primary'}
                role={'link'}
                onClick={() => navigate('/')}
            >
                Summarize another file
            </AixplainButton>
        </ThankYouWrapper>
    )
}

export default ThankYou
