import AixplainButton from '@/components/aixplain-button/AixplainButton.jsx'
import { GlobalErrorFallbackWrapper } from '@/components/error-boundary/styles.js'

const GlobalErrorFallback = () => {
    return (
        <GlobalErrorFallbackWrapper>
            <h1>Something went wrong!</h1>
            <AixplainButton
                variant={'primary'}
                onClick={() => {
                    window.location.assign('/')
                }}
            >
                Go to Homepage
            </AixplainButton>
        </GlobalErrorFallbackWrapper>
    )
}

export default GlobalErrorFallback
