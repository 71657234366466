import { useMutation, useQueryClient } from '@tanstack/react-query'
import toast from 'react-hot-toast'
import { useNavigate } from 'react-router-dom'
import * as yup from 'yup'

import { useWindowSize } from '@/hooks/useWindowSize.jsx'
import UploadAudioForm from '@/pages/upload-audio/UploadAudioForm.jsx'
import UploadedAudioPlayer from '@/pages/upload-audio/UploadedAudioPlayer.jsx'
import { summarizeAudioFile } from '@/services/summarization.js'
import * as routeNames from '@/utilities/constants/index.js'

import SummarizationTemplate from '../../templates/summarization/SummarizationTemplate.jsx'

const formSchema = yup.object().shape({
    email: yup
        .string()
        .email('Enter a valid email')
        .required('This field is required'),

    file: yup.mixed().required('You need to upload a file first!'),
    language_id: yup.number().required('This field is requires'),
})
const UploadAudio = () => {
    const windowSize = useWindowSize()
    const navigate = useNavigate()
    const isSm = windowSize.width < 768
    const queryClient = useQueryClient()

    const { mutate, isLoading } = useMutation({
        mutationFn: payload => summarizeAudioFile(payload),
        onSuccess: () => {
            queryClient.invalidateQueries(['user-summarizations']).then(() => {
                navigate({
                    pathname: routeNames.THANK_YOU,
                })
            })
        },
        onError: error => {
            toast.error('Ops! Something went wrong, try again later.')
            console.error(error)
        },
    })
    const onSubmit = values => {
        mutate({ ...values, language_id: parseInt(values.language_id) })
    }
    return (
        <>
            <SummarizationTemplate
                title={'Audio file'}
                steps={[
                    'Upload an audio file',
                    "Select the recording's language",
                    'Enter the email on which you want to be notified',
                    `Press "Summarize it"`,
                ]}
                formSchema={formSchema}
                onSubmit={onSubmit}
                isLoading={isLoading}
                defaultValues={{ file: null }}
                additionalContent={!isSm && <UploadedAudioPlayer />}
            >
                <UploadAudioForm />
            </SummarizationTemplate>
        </>
    )
}

export default UploadAudio
