export const Logo = () => {
    return (
        <svg
            width="128"
            height="30"
            viewBox="0 0 128 30"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0_70_231)">
                <path
                    d="M60 5C65.5229 5 70 9.47715 70 15C70 20.5228 65.5229 25 60 25C57.7486 25 55.671 24.256 53.9996 23.0004L54 30H50V5L52.9289 7.92893C54.7386 6.11929 57.2386 5 60 5ZM32.7667 0L38.786 11.286L43.5001 5H48.4999L40.862 15.182L48.7667 30H44.2333L38.214 18.714L33.5001 25H28.5001L36.136 14.82L28.2333 0H32.7667ZM118 5C123.523 5 128 9.47715 128 15V25H124V15C124 11.6863 121.314 9 118 9C114.686 9 112 11.6863 112 15V25H108V5L110.929 7.92893C112.739 6.11929 115.239 5 118 5ZM105 5V25H101V5H105ZM98 5V25L95.0711 22.0711C93.2614 23.8807 90.7614 25 88 25C82.4771 25 78 20.5228 78 15C78 9.47715 82.4771 5 88 5C90.7624 5 93.2632 6.1201 95.073 7.93089L98 5ZM76 0V25H72V0H76ZM27 5V25H23V5H27ZM20 5V25L17.0711 22.0711C15.2614 23.8807 12.7614 25 10 25C4.47715 25 0 20.5228 0 15C0 9.47715 4.47715 5 10 5C12.7624 5 15.2632 6.1201 17.073 7.93089L20 5ZM88 9C84.6863 9 82 11.6863 82 15C82 18.3137 84.6863 21 88 21C91.3137 21 94 18.3137 94 15C94 11.6863 91.3137 9 88 9ZM60 9C56.6863 9 54 11.6863 54 15C54 18.3137 56.6863 21 60 21C63.3137 21 66 18.3137 66 15C66 11.6863 63.3137 9 60 9ZM10 9C6.68629 9 4 11.6863 4 15C4 18.3137 6.68629 21 10 21C13.3137 21 16 18.3137 16 15C16 11.6863 13.3137 9 10 9ZM105 0V3H101V0H105ZM27 0V3H23V0H27Z"
                    fill="white"
                />
            </g>
            <defs>
                <clipPath id="clip0_70_231">
                    <rect width="128" height="30" fill="white" />
                </clipPath>
            </defs>
        </svg>
    )
}
