import styled, { css } from 'styled-components'

export const CenteredWrapper = styled.div`
    ${({ theme: { spacing } }) => css`
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: ${spacing * 2}px;
    `}
`

export const StyledErrorText = styled.h1`
    ${({ theme: { typography } }) => css`
        ${typography.textXl}
        ${typography.fontBold}
    `}
`
