import { useQuery } from '@tanstack/react-query'

import { getFileSummarization } from '@/services/summarization.js'

export const useFileSummarization = (id, password, queryOptions) => {
    return useQuery({
        queryKey: ['file-summarizations', id],
        queryFn: () => getFileSummarization(id, password),
        ...queryOptions,
    })
}
