import * as RDropdownMenu from '@radix-ui/react-dropdown-menu'
import PropTypes from 'prop-types'

import {
    StyledDropdownContent,
    StyledTrigger,
} from '@/components/dropdown-menu/styles.js'

const DropdownMenu = ({ actions, children }) => {
    return (
        <RDropdownMenu.Root>
            <StyledTrigger>{children}</StyledTrigger>

            <RDropdownMenu.Portal>
                <StyledDropdownContent sideOffset={16}>
                    {actions.map(action => {
                        return (
                            <RDropdownMenu.Item
                                key={action.label}
                                className={'dropdown-item'}
                                onSelect={action.onSelect}
                            >
                                {action.icon}
                                <span>{action.label}</span>
                            </RDropdownMenu.Item>
                        )
                    })}
                </StyledDropdownContent>
            </RDropdownMenu.Portal>
        </RDropdownMenu.Root>
    )
}

DropdownMenu.propTypes = {
    actions: PropTypes.arrayOf(PropTypes.object).isRequired,
    children: PropTypes.node.isRequired,
}

export default DropdownMenu
