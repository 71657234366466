import PropTypes from 'prop-types'

export const GoogleMeetIcon = ({ size = 24 }) => {
    return (
        <svg
            width={size}
            height={size}
            viewBox="0 0 38 38"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M11.9908 8.01962L6 14.108H11.9908V8.01962ZM12.422 8.01962V14.108H20.664V18.0621L26.5898 13.2413C26.5747 11.9196 26.6234 10.8038 26.5628 9.49295C26.4024 8.31212 25.1685 7.90045 24.1404 8.01962H12.422ZM31.025 10.3921C30.1562 10.7117 29.5538 11.47 28.8074 12.0063C26.0926 14.2271 23.4103 16.4696 20.7171 18.7121C23.9942 21.518 27.2604 24.3238 30.5375 27.1188C31.857 27.8457 32.0845 26.237 31.9762 25.3421V11.2588C31.9759 11.1367 31.9508 11.0159 31.9022 10.9038C31.8537 10.7918 31.7827 10.6908 31.6938 10.6071C31.6048 10.5234 31.4997 10.4588 31.3849 10.4172C31.2701 10.3755 31.148 10.3578 31.0261 10.365L31.025 10.3921ZM6.04117 14.5413V22.883H12.032V14.5413H6.04117ZM20.7496 23.3163H12.5087V29.4046C16.6947 29.3981 20.8904 29.4165 25.0537 29.3949C26.1587 29.319 26.8065 28.1815 26.644 27.1524V24.4332L20.7182 19.3632V23.3174L20.7496 23.3163ZM6.04117 23.3163C6.06283 24.8763 5.99675 26.4363 6.07692 27.9854C6.25242 29.0124 7.33142 29.5346 8.2945 29.4046H12.0472V23.3163H6.04117Z"
                fill="currentColor"
            />
        </svg>
    )
}

GoogleMeetIcon.propTypes = {
    size: PropTypes.number,
}
