import styled, { css } from 'styled-components'

import AixplainButton from '@/components/aixplain-button/AixplainButton.jsx'
import { HEADER_HEIGHT } from '@/utilities/constants/index.js'
export const StyledDownloadButton = styled(AixplainButton)`
    ${({ theme: { spacing, mediaQueries, palette } }) => css`
        display: flex;
        align-items: center;
        gap: ${spacing}px;
        height: 40px;
        color: ${palette.background};
        background: ${palette.white};
        padding: ${spacing * 1.5}px ${spacing * 3}px;
        ${mediaQueries.s} {
            .text {
                display: none;
            }
        }
    `}
`
export const HeaderWrapper = styled.div`
    ${({ theme: { spacing, palette } }) => css`
        padding-left: ${spacing * 2}px;
        padding-right: ${spacing * 2}px;
        height: ${HEADER_HEIGHT}px;
        min-height: 86px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-bottom: 1px solid ${palette.darkerGray};
    `}
`

export const HeaderContentWrapper = styled.div`
    ${({ theme: { spacing, mediaQueries } }) => css`
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: 1220px;
        max-width: 100%;

        .logo-container {
            gap: ${spacing * 2}px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
        }

        ${mediaQueries.s} {
            .logo-container {
                zoom: 0.7;
            }
        }
    `}
`

export const StyledMenuTrigger = styled.div`
    ${({ theme: { spacing, typography, mediaQueries } }) => css`
        display: flex;
        align-items: center;
        justify-content: center;
        gap: ${spacing}px;
        ${typography.fontSemiBold}
        ${typography.textLg}

        ${mediaQueries.m} {
            .title {
                display: none;
            }
        }
    `}
`
