import * as RTooltip from '@radix-ui/react-tooltip'
import PropTypes from 'prop-types'
import { useTheme } from 'styled-components'

import { StyledTooltipContent } from '@/components/tooltip/styles.js'

const Tooltip = ({
    open,
    children,
    content,
    side = 'bottom',
    triggerAsChild,
}) => {
    const { palette } = useTheme()
    return (
        <RTooltip.Root open={open} delayDuration={200}>
            <RTooltip.Trigger asChild={triggerAsChild}>
                {children}
            </RTooltip.Trigger>
            <RTooltip.Portal>
                <StyledTooltipContent side={side} sideOffset={4}>
                    {content}
                    <RTooltip.Arrow fill={palette.mediumGray} />
                </StyledTooltipContent>
            </RTooltip.Portal>
        </RTooltip.Root>
    )
}

Tooltip.propTypes = {
    open: PropTypes.bool,
    children: PropTypes.node.isRequired,
    content: PropTypes.node.isRequired,
    side: PropTypes.string,
    triggerAsChild: PropTypes.bool,
}

export default Tooltip
