import { useMutation, useQueryClient } from '@tanstack/react-query'
import toast from 'react-hot-toast'
import { useNavigate } from 'react-router-dom'
import * as yup from 'yup'

import AudioLinkForm from '@/pages/audio-link/AudioLinkForm.jsx'
import { summarizeAudioLink } from '@/services/summarization.js'
import * as routeNames from '@/utilities/constants/index.js'

import SummarizationTemplate from '../../templates/summarization/SummarizationTemplate.jsx'

const ALLOWED_EXTENDSION = ['.wav', '.mp3', '.m4a', '.mp4']

const formSchema = yup.object().shape({
    email: yup
        .string()
        .email('Enter a valid email')
        .required('This field is required'),

    url: yup
        .string()
        .url('Enter a valid url')
        .test(
            'invalid-extension',
            `Check that the uploaded file has one of these extension ${ALLOWED_EXTENDSION.join(
                ', '
            )}`,
            function (val) {
                return ALLOWED_EXTENDSION.some(ext => val.endsWith(ext))
            }
        )
        .required('This field is required'),
    language_id: yup.number().required('This field is requires'),
})
const UploadAudio = () => {
    const navigate = useNavigate()
    const queryClient = useQueryClient()

    const { mutate, isLoading } = useMutation({
        mutationFn: payload => summarizeAudioLink(payload),
        onSuccess: () => {
            queryClient.invalidateQueries(['user-summarizations']).then(() => {
                navigate({
                    pathname: routeNames.THANK_YOU,
                })
            })
        },
        onError: error => {
            toast.error('Ops! Something went wrong, try again later.')
            console.error(error)
        },
    })
    const onSubmit = values => {
        mutate({ ...values, language_id: parseInt(values.language_id) })
    }
    return (
        <SummarizationTemplate
            isLoading={isLoading}
            title={'Audio link'}
            steps={[
                'Upload an audio link',
                "Select the recording's language",
                'Enter the email on which you want to be notified',
                `Press "Summarize it"`,
            ]}
            defaultValues={{ url: '' }}
            formSchema={formSchema}
            onSubmit={onSubmit}
        >
            <AudioLinkForm />
        </SummarizationTemplate>
    )
}

export default UploadAudio
