import { useQuery } from '@tanstack/react-query'

import { getUserSummarization } from '@/services/summarization.js'

export const useUserSummarizations = (id, password, queryOptions) => {
    return useQuery({
        queryKey: ['user-summarizations', id],
        queryFn: () => getUserSummarization(id, password),
        ...queryOptions,
    })
}
