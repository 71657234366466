// Public
export const ROUTE_UPLOAD_AUDIO = '/upload-audio'
export const ROUTE_AUDIO_LINK = '/audio-link'

export const SUMMARIZATIONS = '/summarizations'

export const GOOGLE_MEET = '/google-meet'

export const ZOOM = '/zoom'

export const SUMMARY = '/summary'
export const THANK_YOU = '/thank-you'
